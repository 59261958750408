import React, {Component} from 'react'
import { connect } from 'react-redux'


class AlertContainer extends Component { 
	
  	render(){
  		const { alert } = this.props;
  		if( !alert.message ) return false

  		return <div 
  			className={`alert ${alert.type}`}
  			style={{margin: "12px"}} >
        <span>{alert.message}</span>
      </div>
  	}
}

const mapStateToProps = (state, ownProps) => {
	const { alert } = state;
	return {
		alert: alert,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);  