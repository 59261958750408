import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
} from '../actions/auth'

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case AUTH_LOGIN_FAILURE:
      return {};
    case AUTH_LOGOUT:
      return {};
    default:
      return state
  }
}

export default auth