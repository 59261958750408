import {fetchApi} from '../helpers';

export const authService = {
    login,
    logout,
};

function login(email, password) {

    const res  = fetchApi('auth/login', {
        email: email,
        password: password
    },'application/x-www-form-urlencoded') 

    return res
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}