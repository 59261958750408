import React from 'react';
import AlertContainer from '../../AlertContainer';


const Login = ({
  onSubmit,
  onChange,
  errors,
  user
}) => (
  
  <div className="container">
    <AlertContainer />
    <form action="/" onSubmit={onSubmit}>
      <h2 className="card-heading">Login</h2>

      <div className={'form-group'}>
          <label htmlFor="username">Username</label>
          <input type="text" 
            className="form-control" 
            name="email" 
            value={user.email} 
            onChange={onChange} />
      </div>
      <div className={'form-group'}>
          <label htmlFor="password">Password</label>
          <input type="password" 
            className="form-control" 
            name="password" 
            value={user.password} 
            onChange={onChange} />
      </div>
      <div className="form-group">
          <button type="submit" className="btn btn-primary">Login</button>
      </div>

    </form>
  </div>
);

export default Login;